import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

export default function SolutionsDropdown(props) {
    const { searchParameters, setSearchParameters, newSearch } = props;

    const handleCheckboxChange = (e) => {
        if(!searchParameters?.filters?.solution.includes(e.target.value)){
            setSearchParameters({
                ...searchParameters,
                filters: {
                    ...searchParameters.filters,
                    solution: [...searchParameters?.filters?.solution, e.target.value],
                    content_type: [...searchParameters?.filters?.content_type, e.target.value]
                }
                
            })
        } else {
            const newArray = searchParameters?.filters?.solution?.filter(item => item !== e.target.value);
            const newArray2 = searchParameters?.filters?.content_type?.filter(item => item !== e.target.value);
            setSearchParameters({
                ...searchParameters,
                filters: {
                    ...searchParameters.filters,
                    solution: newArray,
                    content_type: newArray2
                }
            })
        }
    }

    const getCheckboxValue = (val) => {
        return searchParameters?.filters?.solution?.includes(val)
    }

    return (
        <Container className="filter-container">
            <Column>
                <InputWrap>
                <label><input type="checkbox" value="Digital Transformation" checked={getCheckboxValue('Digital Transformation')} onChange={(e) => handleCheckboxChange(e)}></input>
                    Digital Transformation</label>
                </InputWrap>
                <InputWrap>
                <label><input type="checkbox" value="Litigation and Investigations" checked={getCheckboxValue('Litigation and Investigations')} onChange={(e) => handleCheckboxChange(e)} ></input>
                    Litigation &amp; Investigation</label>
                </InputWrap>
                <InputWrap>
                <label><input type="checkbox" value="Contracting" checked={getCheckboxValue('Contracting')} onChange={(e) => handleCheckboxChange(e)} ></input>
                    Contracting</label>
                </InputWrap>
                <InputWrap>
                <label><input type="checkbox" value="Intellectual Property" checked={getCheckboxValue('Intellectual Property')} onChange={(e) => handleCheckboxChange(e)} ></input>
                    Intellectual Property</label>
                </InputWrap>
            </Column>
            <Column>
                <InputWrap>
                <label><input type="checkbox" value="Operations, Risk and Compliance" checked={getCheckboxValue('Operations, Risk and Compliance')} onChange={(e) => handleCheckboxChange(e)} ></input>
                    Operations, Risk, &amp; Compliance</label>
                </InputWrap>
                {/* <InputWrap>
                <label><input type="checkbox" value="Corporate Solutions" checked={getCheckboxValue('Corporate Solutions')} onChange={(e) => handleCheckboxChange(e)} ></input>
                    Corporate Solutions</label>
                </InputWrap> */}
                {/* <InputWrap>
                <label><input type="checkbox" value="Law Department Consulting" checked={getCheckboxValue('Law Department Consulting')} onChange={(e) => handleCheckboxChange(e)} ></input>
                    Law Department Consulting</label>
                </InputWrap> */}
            </Column>
            <Column>
                <Apply onClick={() => newSearch()}>Apply</Apply>
            </Column>
            <Column></Column>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    position: absolute;
    z-index: 200;
    background-color: white;
    width: 100%;
    top: 420px;
    flex-wrap: wrap;
    @media(max-width: 1150px){
        > div:last-child{
            display: none;
        }
    }
`

const Column = styled.div`
    width: 25%;
    padding: 24px 64px;
    @media(max-width: 1400px){
        padding: 24px 32px;
    }
    @media(max-width: 1150px){
        width: 33%;
    }
    @media(max-width: 480px){
        padding: 20px 10px;
        width: 50%;
    }
    `

const InputWrap = styled.div`
    margin: 8px 0;
    input {
        margin-right: 8px;
        border-radius: 0px;
    }
`

const Apply = styled.div`
    background-color: #E91947;
    color: white;
    width: 160px;
    max-width:100%;
    padding: 8px 0;
    text-align: center;
    cursor: pointer;
    &:hover {
        filter: brightness(.9);
    }
`