import React from 'react';
import styled from 'styled-components';

export default function ContentTypesDropdown(props) {
    const { searchParameters, setSearchParameters, newSearch } = props;

    const handleCheckboxChange = (e) => {
        if(e.target.value==='teardown'){
            if(!searchParameters?.filters?.tag.includes(e.target.value)){
                setSearchParameters({
                    ...searchParameters,
                    filters: {
                        ...searchParameters.filters,
                        content_type: ['Teardown Report']
                    }
    
                })
            } else {
                const newArray = searchParameters?.filters?.tag?.filter(item => item !== e.target.value);
                setSearchParameters({
                    ...searchParameters,
                    filters: {
                        ...searchParameters.filters,
                        tag: newArray
                    }
                })
            }
        } else{
            if(!searchParameters?.filters?.content_type.includes(e.target.value)){
                setSearchParameters({
                    ...searchParameters,
                    filters: {
                        ...searchParameters.filters,
                        content_type: [...searchParameters?.filters?.content_type, e.target.value]
                    }
                })
            } else {
                const newArray = searchParameters?.filters?.content_type?.filter(item => item !== e.target.value);
                setSearchParameters({
                    ...searchParameters,
                    filters: {
                        ...searchParameters.filters,
                        content_type: newArray
                    }
                })
            }
        }
    }

    const getCheckboxValue = (val) => {
        if(val!=='teardown')
            return searchParameters?.filters?.content_type?.includes(val)
        else
            return searchParameters?.filters?.tag?.includes(val)
    }
    return (
        <Container className="filter-container">
            <Column>
                <InputWrap>
                <label><input type="checkbox" value="Article" checked={getCheckboxValue('Article')} onChange={(e) => handleCheckboxChange(e)}></input>
                    Article</label>
                </InputWrap>
                <InputWrap>
                <label><input type="checkbox" value="Case Study" checked={getCheckboxValue('Case Study')} onChange={(e) => handleCheckboxChange(e)}></input>
                    Case Study</label>
                </InputWrap>
                {/*<InputWrap>
                <label><input type="checkbox" value="Webinar" checked={getCheckboxValue('Webinar')} onChange={(e) => handleCheckboxChange(e)}></input>
                    Webinar</label>
                </InputWrap>*/}
                <InputWrap>
                <label><input type="checkbox" value="White Paper" checked={getCheckboxValue('White Paper')} onChange={(e) => handleCheckboxChange(e)}></input>
                    White Paper</label>
                </InputWrap>
            </Column>
            <Column>
                <InputWrap>
                <label><input type="checkbox" value="Video" checked={getCheckboxValue('Video')} onChange={(e) => handleCheckboxChange(e)}></input>
                    Video</label>
                </InputWrap>
                {/*<InputWrap>
                <label><input type="checkbox" value="Benchmark Report" checked={getCheckboxValue('Benchmark Report')} onChange={(e) => handleCheckboxChange(e)}></input>
                    Benchmark Report</label>
                </InputWrap>*/}
                <InputWrap>
                <label><input type="checkbox" value="Newsroom" checked={getCheckboxValue('Newsroom')} onChange={(e) => handleCheckboxChange(e)}></input>
                    Newsroom</label>
                </InputWrap>
                <InputWrap>
                <label><input type="checkbox" value="Teardown Report" checked={getCheckboxValue('Teardown Report')} onChange={(e) => handleCheckboxChange(e)}></input>
                    Teardown Report</label>
                </InputWrap>
            </Column>
            <Column>
                <Apply onClick={() => newSearch()}>Apply</Apply>
            </Column>
            <Column></Column>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    position: absolute;
    z-index: 200;
    background-color: white;
    width: 100%;
    top: 420px;
    flex-wrap: wrap;
    @media(max-width: 1150px){
        > div:last-child{
            display: none;
        }
    }
`

const Column = styled.div`
    width: 25%;
    padding: 24px 64px;
    @media(max-width: 1400px){
        padding: 24px 32px;
    }
    @media(max-width: 1150px){
        width: 33%;
    }
    @media(max-width: 480px){
        padding: 20px 10px;
        width: 50%;
    }
    `

const InputWrap = styled.div`
    margin: 8px 0;
    input {
        margin-right: 8px;
        border-radius: 0px;
    }
`

const Apply = styled.div`
    background-color: #E91947;
    color: white;
    width: 160px;
    padding: 8px 0;
    text-align: center;
    cursor: pointer;
    max-width:100%;
    &:hover {
        filter: brightness(.9);
    }
`