import React from 'react';
import styled from 'styled-components';

export default function TagsDropdown(props) {
    const { searchParameters, setSearchParameters, newSearch } = props;

    const handleCheckboxChange = (e) => {
        if (!searchParameters?.filters?.tag.includes(e.target.value)) {
            setSearchParameters({
                ...searchParameters,
                filters: {
                    ...searchParameters.filters,
                    tag: [...searchParameters?.filters?.tag, e.target.value]
                }

            })
        } else {
            const newArray = searchParameters?.filters?.tag?.filter(item => item !== e.target.value);
            setSearchParameters({
                ...searchParameters,
                filters: {
                    ...searchParameters.filters,
                    tag: newArray
                }
            })
        }
    }

    const getCheckboxValue = (val) => {
        return searchParameters?.filters?.tag?.includes(val)
    }

    return (
        <Container  className="filter-container">
            <Column>
                <InputWrap>
                <label><input type="checkbox" value="insight" checked={getCheckboxValue('insight')} onChange={(e) => handleCheckboxChange(e)}></input>
                    insight</label>
                </InputWrap>
                <InputWrap>
                    <label><input type="checkbox" value="solution" checked={getCheckboxValue('solution')} onChange={(e) => handleCheckboxChange(e)}></input>
                    solution</label>
                </InputWrap>
                <InputWrap>
                    <label><input type="checkbox" value="event" checked={getCheckboxValue('event')} onChange={(e) => handleCheckboxChange(e)}></input>
                    event</label>
                </InputWrap>
                <InputWrap>
                    <label><input type="checkbox" value="teardown" checked={getCheckboxValue('teardown')} onChange={(e) => handleCheckboxChange(e)}></input>
                    teardown</label>
                </InputWrap>
                {/* <InputWrap>
                    <label><input type="checkbox" value="Tag 5" checked={getCheckboxValue('Tag 5')} onChange={(e) => handleCheckboxChange(e)}></input>
                    Tag 5</label>
                </InputWrap>
                <InputWrap>
                    <label><input type="checkbox" value="Tag 6" checked={getCheckboxValue('Tag 6')} onChange={(e) => handleCheckboxChange(e)}></input>
                    Tag 6</label>
                </InputWrap> */}
            </Column>
            {/* <Column>
                <InputWrap>
                    <label><input type="checkbox" value="Tag 7" checked={getCheckboxValue('Tag 7')} onChange={(e) => handleCheckboxChange(e)}></input>
                    Tag 7</label>
                </InputWrap>
                <InputWrap>
                    <label><input type="checkbox" value="Tag 8" checked={getCheckboxValue('Tag 8')} onChange={(e) => handleCheckboxChange(e)}></input>
                    Tag 8</label>
                </InputWrap>
                <InputWrap>
                    <label><input type="checkbox" value="Tag 9" checked={getCheckboxValue('Tag 9')} onChange={(e) => handleCheckboxChange(e)}></input>
                    Tag 9</label>
                </InputWrap>
            </Column> */}
            <Column>
                <Apply onClick={() => newSearch()}>Apply</Apply>
            </Column>
            <Column></Column>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    position: absolute;
    z-index: 200;
    background-color: white;
    width: 100%;
    top: 420px;
    flex-wrap: wrap;
    @media(max-width: 1150px){
        > div:last-child{
            display: none;
        }
    }
`

const Column = styled.div`
    width: 25%;
    padding: 24px 64px;
    @media(max-width: 1400px){
        padding: 24px 32px;
    }
    @media(max-width: 1150px){
        width: 33%;
    }
    @media(max-width: 480px){
        padding: 20px 10px;
        width: 50%;
    }
    `

const InputWrap = styled.div`
    margin: 8px 0;
    input {
        margin-right: 8px;
        border-radius: 0px;
    }
`

const Apply = styled.div`
    background-color: #E91947;
    color: white;
    width: 160px;
    max-width:100%;
    padding: 8px 0;
    text-align: center;
    cursor: pointer;
    &:hover {
        filter: brightness(.9);
    }
`