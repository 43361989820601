import React, { Children } from "react";
/* BEM */
import BEMHelper from "react-bem-helper";

const bem = new BEMHelper({
  name: "",
});

const Section = (props) => {
  const { className, variant, sectionType, children, id } = props;

  const bem = new BEMHelper({
    name: `${className}`,
  });

  return (
    <section {...bem(null, variant || null, sectionType)} id={id ? id : null}>
      {children}
    </section>
  );
};

export default Section;
