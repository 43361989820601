import React, { useEffect, useState } from "react";
import BEMHelper from "react-bem-helper";
import ModuleSearchResults from "../modules/module-search-results";
import { siteSearch } from "../utils/siteSearch";
import { navigate, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const bem = new BEMHelper({
  name: "search",
});

const Search = ({ data, location, pageContext }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [queryTerm, setQueryTerm] = useState("");

  useEffect(() => {
    // document.getElementById("loader_wrap").classList.add("d-none");

    const incomingResults = location?.state?.searchResults;
    const incomingQueryTerm = location?.state?.queryTerm;
    if (!incomingResults) {
      siteSearch().then((result) => {
        navigate("/search", {
          state: { searchResults: result.data },
        });
      });
    } else {
      setSearchResults(incomingResults);
      setQueryTerm(incomingQueryTerm);
    }
  }, [location.state, searchResults]);

  // Content Types (insight types) and Solutions that will be
  // used to populate the faceting
  const insightTypes = data.insightTypes.nodes;
  const solutions = data.solutions.nodes;

  return (
    <Layout>
      <SEO title={"Search UnitedLex"} description={""} url={location.href} />
      <section {...bem()} data-swiftype-index="false">
        <div>
          <ModuleSearchResults
            searchResults={searchResults || null}
            queryTerm={queryTerm || location?.state?.queryTerm}
          />
        </div>
      </section>
    </Layout>
  );
};

export default Search;

export const pageQuery = graphql`
  query {
    insightTypes: allContentfulCategoryInsightType {
      nodes {
        insightType
      }
    }
    solutions: allContentfulTemplateSolution {
      nodes {
        pageTitle
      }
    }
  }
`;
