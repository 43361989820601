import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import ContentTypesDropdown from "../components/search-page/drop-downs/ContentTypes";
import SolutionsDropdown from "../components/search-page/drop-downs/Solutions";
import TagsDropdown from "../components/search-page/drop-downs/Tags";
import { navigate } from "gatsby";
import { siteSearch } from "../utils/siteSearch";
/* BEM */
import BEMHelper from "react-bem-helper";
import Section from "../components/section";
import SearchLens from "../blocks/search-lens";
import CloseIcon from "../blocks/close-icon";
import MobileMenuArrow from "../blocks/mobile-menu-arrow";
import articleFallbackImg from "../assets/img/patterns/article-fallback.png";

import { getUrlParts } from "../helpers/helpers";
const sectionName = "module-search-results";

const bem = new BEMHelper({
  name: sectionName,
});

const ModuleSearchResults = ({ searchResults, queryTerm }) => {
  const [sort, setSort] = useState("relevance");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [firstPage, setFirstPage] = useState(0);

  const pageLimit = 10;

  const [searchParameters, setSearchParameters] = useState({
    queryTerm: "",
    filters: {
      solution: [],
      content_type: [],
      tag: [],
    },
    facets: [],
  });

  const initState = {
    // used for quick state replacement
    queryTerm: "",
    filters: {
      solution: [],
      content_type: [],
      tag: [],
    },
    facets: [],
  };

  const [filterDropdown, selectFilterDropdown] = useState(0);
  const [facets, setFacets] = useState([]);

  const selectDropdown = (dropDown) => {
    selectFilterDropdown(dropDown);
  };

  const newSearch = () => {
    selectFilterDropdown(0);
    const { queryTerm, filters, facets } = searchParameters;
    setIsLoading(true);
    siteSearch(queryTerm, filters, facets, "normal").then((result) => {
      setIsLoading(false);
      setPage(0);
      navigate("/search", {
        state: { searchResults: result.data, queryTerm },
      });
    });
  };
  const scrollToTop = (y) => {
    const c = y || document.body.scrollTop;
    if (c > 0) {
      window.scrollTo({
        top: c - c / 8,
        behavior: "smooth",
      });
    }
  };
  const go2page = (p) => {
    setPage(p);
    scrollToTop(150);
  };
  const nextPagination = (p) => {
    setFirstPage(p);
    go2page(p);
  };
  const nextPage = () => {
    if (page === firstPage + 3) setFirstPage(firstPage + 4);
    go2page(page + 1);
  };
  const prevPage = () => {
    if (page === firstPage) setFirstPage(firstPage - 4);
    go2page(page - 1);
  };
  useEffect(() => {
    if (queryTerm) {
      setSearchParameters({
        ...searchParameters,
        queryTerm,
      });
    }
  }, [searchResults, queryTerm]);

  const cleanResults = searchResults?.records?.page.filter(
    (result) =>
      result?.custom_desc?.length > 40 && result?.custom_title?.length > 14
  );

  const hasResults = !!(cleanResults);

  return (
    <Section className={sectionName}>
      <JumboSearch>
        <div className="search-box-wrapper">
          <input
            className="full-input search-input"
            value={searchParameters.queryTerm}
            onChange={(e) =>
              setSearchParameters({ ...initState, queryTerm: e.target.value })
            }
            onKeyDown={(e) => (e.key === "Enter" ? newSearch() : null)}
          />
          <span className="search-icon">
            <SearchLens />
          </span>
          <span
            className="remove-icon"
            onClick={() => setSearchParameters({ ...initState, queryTerm: "" })}
          >
            <CloseIcon />
          </span>
        </div>
      </JumboSearch>
      {filterDropdown === 1 && (
        <ContentTypesDropdown
          selectFilterDropdown={selectFilterDropdown}
          searchParameters={searchParameters}
          setSearchParameters={setSearchParameters}
          newSearch={newSearch}
        />
      )}
      {filterDropdown === 2 && (
        <SolutionsDropdown
          selectFilterDropdown={selectFilterDropdown}
          searchParameters={searchParameters}
          setSearchParameters={setSearchParameters}
          newSearch={newSearch}
        />
      )}
      {filterDropdown === 3 && (
        <TagsDropdown
          selectFilterDropdown={selectFilterDropdown}
          searchParameters={searchParameters}
          setSearchParameters={setSearchParameters}
          newSearch={newSearch}
        />
      )}
      {filterDropdown > 0 && <SearchShadow onClick={() => selectDropdown(0)} />}
      <FiltersBar className="filter-border-color">
        <FilterDropdownsLeft className="search-dropdown-filter">
          <div>Filter by:</div>
          <div
            className="dropdown-item"
            onClick={() =>
              filterDropdown === 1 ? selectDropdown(0) : selectDropdown(1)
            }
          >
            Content Type
            <span className="filter-down-arrow">
              <MobileMenuArrow
                className={
                  filterDropdown === 1
                    ? "dropdown-arrow active"
                    : "dropdown-arrow"
                }
              />
            </span>
          </div>
          <div
            className="dropdown-item"
            onClick={() =>
              filterDropdown === 2 ? selectDropdown(0) : selectDropdown(2)
            }
          >
            Solutions
            <span className="filter-down-arrow">
              <MobileMenuArrow
                className={
                  filterDropdown === 2
                    ? "dropdown-arrow active"
                    : "dropdown-arrow"
                }
              />
            </span>
          </div>
          {/* <div
            className="dropdown-item"
            onClick={() =>
              filterDropdown === 3 ? selectDropdown(0) : selectDropdown(3)
            }
          >
            Tags
            <span className="filter-down-arrow">
              <MobileMenuArrow
                className={
                  filterDropdown === 3
                    ? "dropdown-arrow active"
                    : "dropdown-arrow"
                }
              />
            </span>
          </div> */}
        </FilterDropdownsLeft>
        <FilterDropdownsRight className="sort-wrapper">
          <div>Sort by:</div>
          <div
            onClick={() => setSort("relevance")}
            className={sort === "relevance" ? "active" : ""}
          >
            Relevance
          </div>
          <span className="seperater"></span>
          <div
            onClick={() => setSort("date")}
            className={sort === "date" ? "active" : ""}
          >
            Date
          </div>
        </FilterDropdownsRight>
        {/* <div>
                                <h1>Facets</h1>
                                {facetNames.map((facet, i) => {
                                    const facetProperties = Object.keys(facet);
                                    const facetObject = searchResults?.facets[facet];
                                    return (
                                        <div key={i}>
                                            <p>{facet}</p>
                                            <p>{facetProperties.map(facetProperty => facetObject[facetProperty] ? <p>{facetProperty}<span>:{facetObject[facetProperty]}</span></p> : null)}</p>
                                        </div>
                                    )
                                })}
                            </div> */}
      </FiltersBar>
      
      { hasResults && (
        <>
          <ResultsInfo className="filter-border-color">
            <div>
              Showing {page * pageLimit + 1}-
              {(page + 1) * pageLimit <= cleanResults?.length
                ? (page + 1) * pageLimit
                : cleanResults?.length}{" "}
              of {cleanResults?.length} results
            </div>
          </ResultsInfo>
          <div
            {...bem("top-section", null, "filter-border-color")}
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              borderBottom: "1px solid",
            }}
          >
            {isLoading && (
              <div style={{ width: "100%", height: "80px" }}>
                <div style={{ fontSize: "32px" }}>Loading...</div>
                <div></div>
              </div>
            )}
            {sort === "relevance" &&
              searchResults &&
              cleanResults
                ?.slice(page * pageLimit, (page + 1) * pageLimit)
                .map((result, i) => {
                  const relativeUrl = getUrlParts(result.url).pathname;
                  return (
                    <Link
                      // to={result?.url}
                      to={relativeUrl}
                      className="result-block"
                      style={{
                        textDecoration: "none",
                        border: "1px solid #ddd",
                        display: "flex",
                        width: "50%",
                        opacity: `${isLoading ? ".3" : "1"}`,
                      }}
                      key={i}
                    >
                      <div style={{ width: "30%", padding: "8px" }}>
                        {result?.custom_feat_img || result?.image ? (
                          <img src={result.image}></img>
                        ) : (
                          <img src={articleFallbackImg} alt="Article fallback image" title="Article fallback image" />
                        )}
                      </div>
                      <div style={{ width: "70%", padding: "8px" }}>
                        {result?.custom_title ? (
                          <p className="block-heading">
                            <b>{result?.custom_title}</b>
                          </p>
                        ) : (
                          <p className="block-heading">
                            <b>{result?.title}</b>
                          </p>
                        )}
                        {result?.custom_desc && (
                          <div style={{ maxHeight: "88px", overflowY: "hidden" }}>
                            {result?.custom_desc}
                          </div>
                        )}
                        {/* result?.custom_desc ? <div style={{ maxHeight: '88px', overflowY: 'hidden' }}>{result?.custom_desc}</div> : <div style={{ maxHeight: '88px', overflowY: 'hidden' }} dangerouslySetInnerHTML={{ __html: result?.body }} /> */}
                      </div>
                    </Link>
                  );
                })}
            {sort === "date" &&
              searchResults &&
              cleanResults
                ?.reverse()
                .slice(page * pageLimit, (page + 1) * pageLimit)
                .map((result, i) => {
                  const relativeUrl = getUrlParts(result.url).pathname;
                  return (
                    <Link
                      // to={result?.url}
                      to={relativeUrl}
                      className="result-block"
                      style={{
                        textDecoration: "none",
                        border: "1px solid #999",
                        display: "flex",
                        width: "50%",
                        padding: "8px",
                        opacity: `${isLoading ? ".3" : "1"}`,
                      }}
                      key={i}
                    >
                      <div style={{ width: "30%", padding: "8px" }}>
                        {result?.custom_feat_img ? (
                          <img src={result?.custom_feat_img}></img>
                        ) : (
                          <img
                            src={
                              result?.custom_feat_img ||
                              result?.image ||
                              articleFallbackImg
                            }
                             alt="Article fallback image" title="Article fallback image"
                          />
                        )}
                      </div>
                      <div style={{ width: "70%", padding: "8px" }}>
                        {result?.custom_title ? (
                          <p className="block-heading">
                            <b>{result?.custom_title}</b>
                          </p>
                        ) : (
                          <p className="block-heading">
                            <b>{result?.title}</b>
                          </p>
                        )}
                        {result?.custom_desc && (
                          <div style={{ maxHeight: "88px", overflowY: "hidden" }}>
                            {result?.custom_desc}
                          </div>
                        )}
                        {/* result?.custom_desc ? <div style={{ maxHeight: '88px', overflowY: 'hidden' }}>{result?.custom_desc}</div> : <div style={{ maxHeight: '88px', overflowY: 'hidden' }} dangerouslySetInnerHTML={{ __html: result?.body }} /> */}
                      </div>
                    </Link>
                  );
                })}
          </div>
          <div className="pagination">
            {firstPage < Math.ceil(cleanResults?.length / pageLimit) - 1 && (
              <a
                className={page === firstPage ? "page-btn active" : "page-btn"}
                onClick={() => go2page(firstPage)}
              >
                {firstPage + 1}
              </a>
            )}
            {firstPage + 1 < Math.ceil(cleanResults?.length / pageLimit) - 1 && (
              <a
                className={page === firstPage + 1 ? "page-btn active" : "page-btn"}
                onClick={() => go2page(firstPage + 1)}
              >
                {firstPage + 2}
              </a>
            )}
            {firstPage + 2 < Math.ceil(cleanResults?.length / pageLimit) - 1 && (
              <a
                className={page === firstPage + 2 ? "page-btn active" : "page-btn"}
                onClick={() => go2page(firstPage + 2)}
              >
                {firstPage + 3}
              </a>
            )}
            {firstPage + 3 < Math.ceil(cleanResults?.length / pageLimit) - 1 && (
              <a
                className={page === firstPage + 3 ? "page-btn active" : "page-btn"}
                onClick={() => go2page(firstPage + 3)}
              >
                {firstPage + 4}
              </a>
            )}
            {firstPage + 4 < cleanResults?.length / pageLimit - 1 && (
              <a onClick={() => nextPagination(firstPage + 4)}>...</a>
            )}
            <a
              className={
                page === Math.ceil(cleanResults?.length / pageLimit) - 1
                  ? "page-btn active"
                  : "page-btn"
              }
              onClick={() =>
                go2page(Math.ceil(cleanResults?.length / pageLimit) - 1)
              }
            >
              {Math.ceil(cleanResults?.length / pageLimit)}
            </a>
            {page < Math.ceil(cleanResults?.length / pageLimit) - 1 && (
              <span className="next-btn" onClick={() => nextPage()}>
                <span>Next</span>
                <a className="next-icon">
                  <MobileMenuArrow className="next-arrow" />
                </a>
              </span>
            )}

            {page > 0 && (
              <span className="prev-btn" onClick={() => prevPage()}>
                <a className="prev-icon">
                  <MobileMenuArrow className="prev-arrow" />
                </a>
                <span>Prev</span>
              </span>
            )}
          </div>
        </>
      )}

      
    </Section>
  );
};

export default React.memo(ModuleSearchResults);

const JumboSearch = styled.div`
  background-color: #181747;
  color: white;
  height: 300px;
  padding: 150px 40px 60px;
  input {
    padding: 12px 0;
    border-bottom: 2px solid white;
    color: white;
    outline: none;
    &:focus {
      outline: none;
    }
  }
`;

const FiltersBar = styled.div`
  width: 100%;
  height: 80px;
  padding: 32px;
  border-bottom: 1px solid gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FilterDropdownsLeft = styled.div`
    display: flex;
    width: 50%;
    max-width: 320px;
    justify-content: space-between;
    align-items: center;
    font-size: .9rem;
    > div {
        cursor: pointer;
        &:hover: opacity: .8;
    }
    > div:first-child {
        color: gray;
    }
    `;

const FilterDropdownsRight = styled(FilterDropdownsLeft)`
  max-width: 220px;
`;

const ResultsInfo = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 32px;
  font-size: 0.9rem;
  color: gray;
`;

const SearchShadow = styled.div`
  position: absolute;
  top: 420px;
  opacity: 0.9;
  z-index: 2;
  width: 100%;
  height: 100vh;
  min-height: 2600px;
  background-color: black;
`;
